import {
  FacetResponseData,
  FilterCheckboxItem,
  QueryLabelInfo,
  SpecificFacetType
} from '../../../../shared/models';
import { QueryHelpers } from '../../data';
import { FilterValueWrapperInfo } from '../../models/search-filter';

export class CheckboxFilterQueryItem {
  constructor(public data: FilterCheckboxItem) {}

  private readonly valueWrapper = `'`;

  // actions:

  valueChanged(): void {
    this.data.checked = !this.data.checked;
  }

  // data handle:

  doesHaveAnyValue(): boolean {
    return this.data.checked;
  }

  getContentString(wrapperInfo: FilterValueWrapperInfo): string {
    const { value, checked } = this.data;

    if (value && checked) {
      if (this.data.queries) {
        return this.getStringContentWithInnerQueries(
          this.data.queries,
          value,
          wrapperInfo?.withWrapper
        );
      }

      const withWrapper: boolean = wrapperInfo?.withWrapper;
      const valueWrapper = withWrapper ? this.valueWrapper : '';

      return `${valueWrapper}${value}${valueWrapper}`;
    }

    return '';
  }

  setFacetByResponse(facetResponse: FacetResponseData): void {
    if (facetResponse) {
      const facetType: SpecificFacetType = this.data.specificFacetType;

      const facetNumber = facetResponse[facetType];
      if (typeof facetNumber === 'number') {
        this.data.facet = facetResponse[facetType];
      }
    }
  }

  isSearchItemTheValue(searchTextItem: string, wrapperInfo: FilterValueWrapperInfo): boolean {
    const withWrapper: boolean = wrapperInfo?.withWrapper;
    const handledSearchItem: string = withWrapper
      ? searchTextItem.trim().slice(1, -1)
      : searchTextItem;

    return this.data.value === handledSearchItem;
  }

  getLabelInfo(): QueryLabelInfo {
    if (this.data.checked) {
      const label: string = this.data.labelFull || this.data.label;

      return { value: label };
    }

    return null;
  }

  private getStringContentWithInnerQueries(
    queries: FilterCheckboxItem[],
    value: string,
    withWrapper: boolean
  ): string {
    const checkedElements = queries.filter((query) => query.checked);
    const startValueWrapper = `${QueryHelpers.START_VALUE_WRAPPER}${QueryHelpers.SPACE}`;
    const endValueWrapper = `${QueryHelpers.SPACE}${QueryHelpers.END_VALUE_WRAPPER}`;

    if (checkedElements.length === 1) {
      return `${startValueWrapper}${checkedElements[0].value}${endValueWrapper}`;
    } else if (checkedElements.length > 1) {
      const orSeparatedValues = checkedElements
        .map((query) => query.value)
        .join(`${QueryHelpers.SPACE}${QueryHelpers.MATCH_ANY_OPERATOR}${QueryHelpers.SPACE}`);
      const result = `${startValueWrapper}${orSeparatedValues}${endValueWrapper}`;

      return result;
    } else {
      const valueWrapper = withWrapper ? this.valueWrapper : '';

      return `${valueWrapper}${value}${valueWrapper}`;
    }
  }
}
