import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomPreloadingStrategyService, LangResolver } from './shared/services';
import { AuthGuard } from './shared/guards/auth.guard';
import { SuperAdminGuard } from './shared/guards/super-admin.guard';
import { RedirectLinkComponent } from './modules/redirect-link';
import { PublicGuard } from './shared/guards/public.guard';
import { AddPaymentMethodPageGuard } from './shared/guards/add-payment-method-page.guard';
import { AffiliateComponent } from './modules/affiliate';

const routesChildren: Routes = [
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'folders',
    loadChildren: () => import('./modules/folders/folders.module').then((m) => m.FoldersModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'admin-panel',
    loadChildren: () =>
      import('./modules/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule),
    canActivate: [AuthGuard, SuperAdminGuard]
  },
  {
    path: 'redirect/link/:link',
    component: RedirectLinkComponent,
    loadChildren: () =>
      import('./modules/redirect-link/redirect-link.module').then((m) => m.RedirectLinkModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-payment-method',
    loadChildren: () =>
      import('./modules/add-payment-method/add-payment-method.module').then(
        (m) => m.AddPaymentMethodModule
      ),
    canActivate: [AuthGuard, AddPaymentMethodPageGuard]
  },
  {
    path: 'referral-program',
    loadChildren: () =>
      import('./modules/affiliate/affiliate.module').then((m) => m.AffiliateModule),
    data: { title: 'REFERRAL_PROGRAM.TITLE' },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [PublicGuard]
  }
];

const routes: Routes = [
  {
    path: ':lang',
    children: routesChildren,
    resolve: {
      lang: LangResolver
    }
  },
  {
    path: '',
    redirectTo: 'en',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategyService
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
